import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout.js"

const JobTemplate = ({ data }) => (
    <Layout>
        <h1>{data.job.positions.join(`, `)}</h1>
        <h2>{data.job.relationships.employer.name}</h2>
        <h3>{data.job.location.city}, {data.job.location.state}</h3>
        <h4>{data.job.start_date.month} {data.job.start_date.year} - {data.job.end_date.year !== -1 ? `${data.job.end_date.month} ${data.job.end_date.year}` : `Present`}</h4>
    </Layout>
);

export default JobTemplate

export const query = graphql`
    query($slug: String!) {
        job: nodeExperience(fields: { slug: { eq: $slug } }) {
            positions: field_exp_position_title
            relationships {
              employer: field_exp_employer {
                name
              }
            }
            location: field_location_city {
              city: location_city_city
              state: location_city_state
            }
            start_date: field_start_date {
              month: month_year_month
              year: month_year_year
            }
            end_date: field_end_date {
              month: month_year_month
              year: month_year_year
            }
        }
    }
`